import React from 'react';
import IconBase, { TIconBaseProps, Example as IconBaseExample } from '@atlas/uicorn/lib/Icon/IconBase';

const JiraIcon = React.memo(function JiraIcon(props: TJiraIconProps): React.ReactElement {
    return (
        <IconBase aria-label="Jira" {...props}>
            <path d="M10.624 3C10.6282 3.92166 10.9962 4.80437 11.6479 5.45608C12.2996 6.1078 13.1823 6.47579 14.104 6.48H15.528V7.84C15.528 8.76157 15.8935 9.64551 16.5444 10.2979C17.1953 10.9503 18.0784 11.3179 19 11.32V3.672C18.9979 3.49442 18.9265 3.3247 18.8009 3.19912C18.6753 3.07354 18.5056 3.00208 18.328 3H10.624ZM6.816 6.84C6.82021 7.75954 7.18736 8.64021 7.83757 9.29043C8.48779 9.94064 9.36846 10.3078 10.288 10.312H11.728V11.688C11.7322 12.6075 12.0994 13.4882 12.7496 14.1384C13.3998 14.7886 14.2805 15.1558 15.2 15.16V7.504C15.1979 7.32854 15.1273 7.16085 15.0032 7.03678C14.8791 6.9127 14.7115 6.84208 14.536 6.84H6.816ZM3 10.68C3 11.1366 3.09007 11.5888 3.26505 12.0105C3.44004 12.4323 3.6965 12.8154 4.01975 13.1379C4.34301 13.4604 4.7267 13.716 5.14887 13.89C5.57103 14.064 6.02338 14.1531 6.48 14.152H7.904V15.528C7.90821 16.4475 8.27536 17.3282 8.92558 17.9784C9.57579 18.6286 10.4565 18.9958 11.376 19V11.344C11.3739 11.1664 11.3025 10.9967 11.1769 10.8711C11.0513 10.7455 10.8816 10.6741 10.704 10.672H3V10.68Z" />
        </IconBase>
    );
});

export default JiraIcon;

// #region Types

export type TJiraIconProps = Omit<TIconBaseProps, 'children' | 'aria-label'> &
    Partial<Pick<TIconBaseProps, 'aria-label'>>;

// #endregion

// #region Examples

export const Example =
    typeof IconBaseExample === 'function'
        ? () => <JiraIcon />
        : { ...IconBaseExample, render: JiraIcon, tags: ['logo', 'app'] };

// #endregion
