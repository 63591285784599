import pt from "./pt";
import pl from "./pl";

export default function t(
  text: TDict,
  lang?: "en" | "pt" | "pl" | (string & {}),
  vars?: Array<string | number>
) {
  const localized =
    (lang === "pt" && pt[text]) || (lang === "pl" && pl[text]) || text;
  if (!vars?.length) return localized;
  return vars.reduce<string>((acc, curr, index) => {
    return acc.replace(`{${index}}`, curr.toString());
  }, localized);
}

export type TDict =
  | "Browse by category"
  | "Popular articles"
  | "No data"
  | "Knowledge base of {0}"
  | "Help Center"
  | "Customer Portal"
  | "What can we help you find?"
  | "Search for articles..."
  | "Search results"
  | "Trending articles"
  | "Close"
  | "No results"
  | "January"
  | "February"
  | "March"
  | "April"
  | "May"
  | "June"
  | "July"
  | "August"
  | "September"
  | "October"
  | "November"
  | "December"
  | "Help Center Search"
  | "Search articles in the knowledge base of {0}"
  | "Sort by:"
  | '{0} results for "{1}"'
  | "Relevance"
  | "Date"
  | "Title"
  | "Last updated {0}"
  | "We appreciate your feedback!"
  | "Was this article helpful?"
  | "Vote Up"
  | "Vote Down"
  | "My Tickets"
  | "Company tickets"
  | "If you are a customer, login to view your tickets."
  | "Login"
  | "New ticket"
  | "Add your message..."
  | "{0} is required"
  | "Invalid email"
  | "Sorry, an error occurred while creating the ticket"
  | "Create ticket"
  | "No tickets here"
  | "Once a new ticket is created, its status will be displayed here."
  | "No tickets illustration"
  | "Back"
  | "Customer portal"
  | "Stay updated on the status of all your company's support tickets."
  | "Sorry, an error occurred while sending the message"
  | "Send"
  | "Related articles"
  | "Open tickets"
  | "Closed tickets"
  | "Show all tickets"
  | "Show open tickets"
  | "Show closed tickets"
  | "Created at"
  | "Last message time"
  | "Failed to update priority";
