import type { TDict } from "./t";

const pl: Record<TDict, string> = {
  "Browse by category": "Przeglądaj według kategorii",
  "Popular articles": "Popularne artykuły",
  "No data": "Brak danych",
  "Knowledge base of {0}": "Baza wiedzy {0}",
  "Help Center": "Centrum pomocy",
  "Customer Portal": "Portal klienta",
  "What can we help you find?": "W czym możemy pomóc?",
  "Search for articles...": "Szukaj artykułów...",
  "Search results": "Wyniki wyszukiwania",
  "Trending articles": "Popularne artykuły",
  Close: "Zamknij",
  "No results": "Brak wyników",
  January: "Styczeń",
  February: "Luty",
  March: "Marzec",
  April: "Kwiecień",
  May: "Maj",
  June: "Czerwiec",
  July: "Lipiec",
  August: "Sierpień",
  September: "Wrzesień",
  October: "Październik",
  November: "Listopad",
  December: "Grudzień",
  "Help Center Search": "Wyszukiwanie w Centrum pomocy",
  "Search articles in the knowledge base of {0}":
    "Szukaj artykułów w bazie wiedzy {0}",
  "Sort by:": "Sortuj według:",
  '{0} results for "{1}"': '{0} wyników dla "{1}"',
  Relevance: "Trafność",
  Date: "Data",
  Title: "Tytuł",
  "Last updated {0}": "Ostatnia aktualizacja {0}",
  "We appreciate your feedback!": "Dziękujemy za opinię!",
  "Was this article helpful?": "Czy ten artykuł był pomocny?",
  "Vote Up": "Głosuj za",
  "Vote Down": "Głosuj przeciw",
  "My Tickets": "Moje zgłoszenia",
  "Company tickets": "Zgłoszenia firmowe",
  "If you are a customer, login to view your tickets.":
    "Jeśli jesteś klientem, zaloguj się, aby zobaczyć swoje zgłoszenia.",
  Login: "Zaloguj się",
  "New ticket": "Nowe zgłoszenie",
  "Add your message...": "Dodaj wiadomość...",
  "{0} is required": "{0} jest wymagane",
  "Invalid email": "Nieprawidłowy email",
  "Sorry, an error occurred while creating the ticket":
    "Przepraszamy, wystąpił błąd podczas tworzenia zgłoszenia",
  "Create ticket": "Utwórz zgłoszenie",
  "No tickets here": "Brak zgłoszeń",
  "Once a new ticket is created, its status will be displayed here.":
    "Po utworzeniu nowego zgłoszenia jego status będzie wyświetlany tutaj.",
  "No tickets illustration": "Ilustracja braku zgłoszeń",
  Back: "Powrót",
  "Customer portal": "Portal klienta",
  "Stay updated on the status of all your company's support tickets.":
    "Bądź na bieżąco ze statusem wszystkich zgłoszeń pomocy technicznej Twojej firmy.",
  "Sorry, an error occurred while sending the message":
    "Przepraszamy, wystąpił błąd podczas wysyłania wiadomości",
  Send: "Wyślij",
  "Related articles": "Artykuły powiązane",
  "Open tickets": "Otwarte zgłoszenia",
  "Closed tickets": "Zamknięte zgłoszenia",
  "Show all tickets": "Pokaż wszystkie zgłoszenia",
  "Show open tickets": "Pokaż otwarte zgłoszenia",
  "Show closed tickets": "Pokaż zamknięte zgłoszenia",
  "Created at": "Utworzono",
  "Last message time": "Ostatnia wiadomość",
  "Failed to update priority": "Nie udało się zaktualizować priorytetu",
};

export default pl;
